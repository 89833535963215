const PatientUtils = {
  isNonPeselIdSet(patient) {
    return patient.nopesel && patient.nopesel_docnum && patient.id_type;
  },
  isIdSet(patient) {
    return patient.pesel || PatientUtils.isNonPeselIdSet(patient);
  }
};

export default PatientUtils;
